.comingSoon {
  background-color: #535353;
  font-family: "Spartan", san-serif;
  height: 100vh;
  width: 100vw;
  display: flex;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .comingSoon {
    padding: 0px 20px;
  }
}
