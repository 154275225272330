/*
#0149AD #FF1714 #FF5756 #0049AC #D9D9D8 #000316
 Audiowide
 */

.viewPage {
  background-color: #000;
  font-family: "Exo 2", san-serif;
  color: #d9d9d8;
}

.pageContent {
  margin-bottom: 50px;
  min-height: 85vh;
}

a {
  /* grows all links on hover */
  transition: all 0.2s ease-in-out;
}
a:hover {
  transform: scale(1.1);
}

.title,
h1,
h2,
h3,
h4 {
  font-family: "Audiowide", san-serif;
  color: #000;
  text-shadow: 0 0 6px rgba(202, 228, 225, 0.92),
    0 0 30px rgba(202, 228, 225, 0.34), 0 0 12px rgba(30, 132, 242, 0.52),
    0 0 21px rgba(30, 132, 242, 0.92), 0 0 34px rgba(30, 132, 242, 0.78),
    0 0 54px rgba(30, 132, 242, 0.92);
}

.title {
  font-size: 50px;
}

.image-container,
.image-container-glow {
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-container-glow {
  filter: drop-shadow(0px 0px 10px #0049ac);
}

.section-container {
  background-color: #212529;
  opacity: 95%;
  border-radius: 70px;
  margin-block: 50px;
}
.section-content {
  padding: 40px;
}
.Link {
  cursor: pointer;
  text-decoration: none;
  padding-inline: 10px;
  color: white !important;
}

/* mobile screens */
@media screen and (min-width: 320px) and (max-width: 767px) {
  .section-container {
    padding-bottom: 10px;
  }
  .pageContent {
    margin-top: 100px;
  }
  .logo-image {
    padding-top: 40px;
    width: 50%;
  }
  a {
    transition: unset;
  }
  a:hover {
    transform: unset;
  }
}
