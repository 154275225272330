.navBackgroundColor {
  background-color: #000316;
  filter: drop-shadow(-5px -5px 5px #0049ac);

  padding: 8;
}

.navbar-brand {
  margin-left: 10px;
}

.show,
.collapsing {
  background-color: #000316;
}
