.banner {
  height: 90vh;
  padding-top: 120px;
}
.bannerCarousel {
  height: 90vh;
  padding-top: 120px;
}

/* mobile screens */
@media screen and (min-width: 420px) and (max-width: 992px) {
  .banner,
  .bannerCarousel {
    height: unset;
    padding-top: unset;
  }
  .textContainerBanner {
    padding-top: 20px;
  }
}
@media screen and (min-width: 320px) and (max-width: 419px) {
  .banner,
  .bannerCarousel {
    height: 80vh;
    padding-top: unset;
  }
}

@media screen and (min-width: 200px) and (max-width: 375px) {
  .image-container-glow {
    max-height: 250px;
    max-width: 250px;
  }
}
