.footer {
  clear: both;
  background-color: transparent;
  width: 100%;
  font-family: "Spartan", sans-serif;
  text-align: center;
  padding-bottom: 5px;
  color: #d9d9d8;
}

.footer a {
  color: #d9d9d8;
  text-decoration: none;
}

.footer a:hover {
  color: #b2a2b9;
  text-decoration: underline;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .footer {
    padding-bottom: 15px;
  }
}
